import React, { Component } from "react"
import {
  FormWrapper,
  FormForm,
  FormRow,
  FormTitle,
  FormSuccess,
} from "./form-styles"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  handleSubmit = e => {
    e.preventDefault()
    const button = document.querySelector('input[type="submit"]')
    const form = document.querySelector("form")
    button.disabled = true
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Contact", ...this.state }),
    })
      .then(() => {
        button.disabled = false
        form.reset()
        document.querySelector(".success").style.display = "block"
        document.querySelector(".success > p").style.opacity = 1
      })
      .catch(error => alert(error))
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <FormWrapper className="footer__form anim-in-down">
        <FormTitle>Treat yourself. Request an appointment.</FormTitle>
        <FormForm
          name="Contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="Contact" />
          <FormRow>
            <input
              required
              type="text"
              name="Name"
              aria-label="Name"
              onChange={this.handleChange}
            />
            <label htmlFor="Name">
              Name <span>*</span>
            </label>
          </FormRow>
          <FormRow>
            <input
              required
              type="email"
              name="Email"
              aria-label="Email"
              onChange={this.handleChange}
            />
            <label htmlFor="Email">
              Email <span>*</span>
            </label>
          </FormRow>
          <FormRow>
            <input
              required
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              name="Phone"
              aria-label="Phone"
              onChange={this.handleChange}
            />
            <label htmlFor="Phone">
              Phone (###-###-####) <span>*</span>
            </label>
          </FormRow>
          <FormRow>
            <input
              required
              type="date"
              name="Appointment Date"
              aria-label="Appointment Date"
              onChange={this.handleChange}
            />
            <label htmlFor="Appointment Date">
              Appointment Date <span>*</span>
            </label>
          </FormRow>
          <FormRow>
            <textarea
              required
              name="What would you like to get done?"
              aria-label="What would you like to get done?"
              onChange={this.handleChange}
            ></textarea>
            <label htmlFor="What would you like to get done?">
              What would you like to get done? <span>*</span>
            </label>
            <input type="submit" value="Send Request" />
          </FormRow>
          <FormRow className="success" style={{ display: "none" }}>
            <FormSuccess>
              Thank you! We'll be in touch shortly to follow up on your request.
            </FormSuccess>
          </FormRow>
        </FormForm>
      </FormWrapper>
    )
  }
}

export default Form
