import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Contact from "../contact/contact"
import Form from "../form/form"
import Follow from "../follow/follow"
import Copyright from "../copyright/copyright"

import { FooterWrapper } from "./footer-styles"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityContact {
          edges {
            node {
              address {
                cityState
                label
                street
              }
              hours {
                day
                hours
              }
              phoneNumber
            }
          }
        }
      }
    `}
    render={data => (
      <FooterWrapper>
        <Contact data={data} />
        <Form />
        <Follow />
        <Copyright />
      </FooterWrapper>
    )}
  />
)
