import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import {
  FollowWrapper,
  FollowTitle,
  FollowLine,
  FollowLink,
} from "./follow-styles"

export default () => (
  <StaticQuery
    query={graphql`
      {
        allSanityContact {
          edges {
            node {
              fbLink
              instaLink
            }
          }
        }
      }
    `}
    render={data => {
      const links = data.allSanityContact.edges[0].node

      return (
        <FollowWrapper className="anim-in-down">
          <FollowTitle>Follow Us</FollowTitle>
          <FollowLine />
          <FollowLink
            href={links.fbLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook Link"
          >
            <FontAwesomeIcon icon={faFacebookSquare} size="3x" />
          </FollowLink>
          <FollowLink
            href={links.instaLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram Link"
          >
            <FontAwesomeIcon icon={faInstagram} size="3x" />
          </FollowLink>
        </FollowWrapper>
      )
    }}
  />
)
