import React from "react"
import { CopyWrap, CopyP, CopyA } from "./copyright-styles"

const Copyright = () => {
  return (
    <CopyWrap className="anim-in-down">
      <CopyP>© {new Date().getFullYear()} Artemis Salon</CopyP>
      <CopyP>All Rights Reserved</CopyP>
      <CopyA to="/disclaimer/">Disclaimer</CopyA>
    </CopyWrap>
  )
}

export default Copyright
