import React, { useEffect } from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import {
  MenuClose,
  Menu,
  MenuList,
  MenuItem,
  SocialList,
  SocialItem,
} from "./menu-styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import anime from "animejs"

export default () => {
  useEffect(() => {
    const menu = document.querySelector(".menu")
    anime({
      targets: menu,
      translateX: "100%",
      duration: 1,
    })
  })

  function close_menu() {
    const menu = document.querySelector(".menu")
    menu.classList.add("hidden")
    anime({
      targets: menu,
      translateX: "100%",
      duration: 400,
      easing: "easeInOutCubic",
    })
  }

  return (
    <StaticQuery
      query={graphql`
        {
          allSanityContact {
            edges {
              node {
                fbLink
                instaLink
              }
            }
          }
        }
      `}
      render={data => {
        const links = data.allSanityContact.edges[0].node

        return (
          <Menu className="menu hidden">
            <MenuClose onClick={close_menu}>&times;</MenuClose>
            <MenuList>
              <MenuItem onClick={close_menu}>
                <Link to="/">Home</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/about/">About the Salon</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/gallery/">Gallery</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/pricing/">Pricing</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/blog/">Blog</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/contact/">Contact</Link>
              </MenuItem>
              <MenuItem onClick={close_menu}>
                <Link to="/cancellations/">Cancellations</Link>
              </MenuItem>
            </MenuList>
            <SocialList>
              <SocialItem>
                <a
                  href={links.fbLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook Link"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} size="3x" />
                </a>
              </SocialItem>
              <SocialItem>
                <a
                  href={links.instaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram Link"
                >
                  <FontAwesomeIcon icon={faInstagram} size="3x" />
                </a>
              </SocialItem>
            </SocialList>
          </Menu>
        )
      }}
    />
  )
}
