import styled from "@emotion/styled"
import { fonts, colors, transitions } from "../theme"

export const FollowWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 4rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`

export const FollowTitle = styled.p`
  ${fonts.play};
  font-size: 1.5rem;
  color: ${colors.white};
  margin: 0;
`

export const FollowLine = styled.div`
  @media (min-width: 640px) {
    width: 4rem;
    height: 2px;
    background-color: ${colors.white};
    margin: 0 1rem;
  }
`

export const FollowLink = styled.a`
  margin: 1rem 0 0;

  @media (min-width: 640px) {
    margin: 0 1rem;
  }

  svg {
    color: ${colors.white};
    ${transitions.default};

    &:hover {
      color: ${colors.olive};
    }
  }
`
