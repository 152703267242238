import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"
import { Link } from "gatsby"

export const CopyWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.5rem;
  ${fonts.mont.bold};

  @media (min-width: 640px) {
    flex-direction: row;
  }
`

export const CopyP = styled.p`
  color: ${colors.white};
  margin: 0;
  margin-right: 1rem;
`

export const CopyA = styled(Link)`
  color: ${colors.olive};
  text-decoration: none;
  ${transitions.default};

  @media (max-width: 639px) {
    margin-top: 0.5rem;
  }

  &:hover {
    color: ${colors.white};
  }
`
