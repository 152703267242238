export const colors = {
  white: "#fefefe",
  black: "#0a0a0a",
  army: "#171811",
  olive: "#afb770",
  darkolive: "#545937",
  gray: "#f4f6f6",
  tan: "#eae4dc",
}

export const fonts = {
  lato: {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    black: {
      fontWeight: 900,
    },
  },
  mont: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    bold: {
      fontWeight: 700,
    },
  },
  play: {
    fontFamily: "Playfair Display",
    fontWeight: 700,
    fontStyle: "italic",
  },
}

export const transitions = {
  default: {
    transition: "all 0.4s ease-in-out",
  },
}
