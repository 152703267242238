import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"

export const FormWrapper = styled.section`
  padding: 2rem;

  @media (min-width: 1024px) {
    padding: 6rem 7.5%;
  }
`

export const FormTitle = styled.p`
  ${fonts.play};
  font-size: 3rem;
  color: ${colors.olive};
  text-transform: capitalize;
  margin-bottom: 4rem;
  text-align: center;

  @media (min-width: 640px) {
    text-align: left;
  }
`

export const FormForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    width: 25%;
  }

  &:nth-of-type(5),
  &:last-of-type {
    width: 100%;
  }

  input:not([type="submit"]),
  textarea {
    width: 100%;
    height: 4rem;
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 2px solid ${colors.white};
    color: ${colors.white};

    @media (min-width: 1024px) {
      width: calc(100% - 1rem);
    }
  }

  textarea {
    height: 8rem;
  }

  input[type="submit"] {
    align-self: flex-end;
    margin-top: 2rem;
    border: none;
    box-shadow: none;
    background-color: ${colors.darkolive};
    color: ${colors.white};
    ${fonts.mont.bold};
    text-transform: uppercase;
    padding: 1.5rem 3rem;
    font-size: 0.875rem;
    ${transitions.default};
    letter-spacing: 3px;

    @media (min-width: 1024px) {
      margin-top: 1rem;
      margin-right: 0.5rem;
    }

    &:hover {
      cursor: pointer;
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  label {
    display: block;
    color: ${colors.white};
    ${fonts.mont.bold};
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-self: flex-start;
    margin-left: 0.5rem;

    span {
      color: ${colors.olive};
    }
  }
`

export const FormSuccess = styled.p`
  color: ${colors.white};
  ${fonts.mont.bold};
  margin-top: 2rem;
  opacity: 0;
  text-align: center;
`
