import "normalize.css"
import React from "react"
import Header from "../components/header/header"
import Menu from "../components/menu/menu"
import Footer from "../components/footer/footer"
import { Global, css } from "@emotion/core"
import { fonts, colors } from "../components/theme"
import { useEffect } from "react"
import anime from "animejs"

const Layout = ({ children }) => {
  useEffect(() => {
    function animInDown() {
      const inDown = document.querySelectorAll(".anim-in-down")

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            anime({
              targets: entry.target,
              opacity: "1",
              translateY: "0rem",
              duration: 800,
              easing: "easeInOutSine",
            })
          }
        })
      })

      inDown.forEach(el => {
        anime({
          targets: el,
          opacity: "0",
          translateY: "-6rem",
          duration: 0,
        })
        observer.observe(el)
      })
    }

    function animInUp() {
      const inUp = document.querySelectorAll(".anim-in-up")

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            anime({
              targets: entry.target,
              opacity: "1",
              translateY: "0rem",
              duration: 800,
              easing: "easeInOutSine",
            })
          }
        })
      })

      inUp.forEach(el => {
        anime({
          targets: el,
          opacity: "0",
          translateY: "6rem",
          duration: 0,
        })
        observer.observe(el)
      })
    }

    animInDown()
    animInUp()
  })

  return (
    <>
      <Global
        styles={css`
          html {
            ${fonts.mont};

            body {
              max-width: 100vw;
              overflow-x: hidden;
            }

            * {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              box-sizing: border-box;
              max-width: 100vw;
            }

            img {
              max-width: 100%;
            }

            h2,
            h3 {
              margin-bottom: 0;
              margin-top: 2rem;
              line-height: 1.25;
            }

            h1 {
              ${fonts.play};
              font-size: 3rem;
              color: ${colors.darkolive};
              margin: 0;
              line-height: 1;
            }

            h2 {
              ${fonts.mont.bold};
              font-size: 1.75rem;
              color: ${colors.darkolive};
            }

            h3 {
              ${fonts.play};
              color: ${colors.olive};
            }

            div {
              p {
                margin-top: 0.5rem;
              }

              a {
                color: ${colors.olive};

                &:hover {
                  color: ${colors.darkolive};
                }
              }
            }
          }
        `}
      />
      <Header />
      <Menu />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
