import styled from "@emotion/styled"
import { colors, fonts } from "../theme"

export const ContactLeft = styled.div`
  background-color: ${colors.gray};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 8rem 4rem;
  }
`

export const ContactLeftTitle = styled.p`
  color: ${colors.darkolive};
  ${fonts.play}
  font-size: 2.5rem;
  margin: 0 0 4rem;
`

export const ContactLeftAddress = styled.p`
  ${fonts.mont};
  font-size: 1.25rem;
  margin: 0;
  color: ${colors.army};
`

export const ContactLeftPhoneNumber = styled.a`
  color: ${colors.darkolive};
  ${fonts.play};
  font-size: 2.5rem;
  margin: 4rem 0 0;
  text-decoration: none;
`

export const ContactRight = styled.ul`
  background-color: ${colors.darkolive};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  list-style: none;
  margin: 0;

  @media (min-width: 1024px) {
    position: relative;
    top: 4rem;
    padding: 6rem 5%;
  }

  @media (min-width: 1440px) {
    padding: 6rem 10%;
  }
`

export const ContactRightItem = styled.li`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 0.5rem auto;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 1rem 0;
  }

  @media (min-width: 1024px) {
    width: 90%;
  }
`

export const ContactRightDay = styled.p`
  ${fonts.play};
  font-size: 2rem;
  position: relative;
  margin: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  @media (min-width: 768px) {
    margin-right: 4rem;
  }

  &::after {
    width: 100%;
    height: 2px;
    background-color: ${colors.olive};
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
  }
`

export const ContactRightHours = styled.p`
  ${fonts.mont.bold};
  font-size: 0.875rem;
  margin: 0;
  text-transform: uppercase;
`

export const ContactWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: ${colors.darkolive};

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  ${ContactLeft}, ${ContactRight} {
    width: 100%;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }
`
