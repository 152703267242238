import React from "react"
import {
  ContactWrapper,
  ContactLeft,
  ContactLeftTitle,
  ContactLeftAddress,
  ContactLeftPhoneNumber,
  ContactRight,
  ContactRightItem,
  ContactRightDay,
  ContactRightHours,
} from "./contact-styles"

import { Global, css } from "@emotion/core"

const Contact = ({ data }) => {
  const phoneNumber = data.allSanityContact.edges[0].node.phoneNumber
  let cleanNumber = `tel:${phoneNumber.replace("/D+/", "")}`
  cleanNumber = cleanNumber.replace(".", "")
  cleanNumber = cleanNumber.replace(".", "")
  const address = data.allSanityContact.edges[0].node.address
  const hours = data.allSanityContact.edges[0].node.hours

  return (
    <>
      <Global
        styles={css`
          ${ContactLeft} {
            @media (max-width: 1023px) {
              padding-top: 8rem !important;
            }
          }
        `}
      />
      <ContactWrapper>
        <ContactLeft className="anim-in-up">
          <ContactLeftTitle>Location + Hours of Operation</ContactLeftTitle>
          <ContactLeftAddress>{address.label}</ContactLeftAddress>
          <ContactLeftAddress>{address.street}</ContactLeftAddress>
          <ContactLeftAddress>{address.cityState}</ContactLeftAddress>
          <ContactLeftPhoneNumber href={cleanNumber}>
            {phoneNumber}
          </ContactLeftPhoneNumber>
        </ContactLeft>
        <ContactRight className="anim-in-down">
          {Array.from(hours).map(({ day, hours }) => (
            <ContactRightItem key={day}>
              <ContactRightDay>{day}</ContactRightDay>
              <ContactRightHours>{hours}</ContactRightHours>
            </ContactRightItem>
          ))}
        </ContactRight>
      </ContactWrapper>
    </>
  )
}

export default Contact
