import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import anime from "animejs"

import {
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderLink,
  HeaderButton,
} from "./header-styles"

export default () => {
  function open_menu() {
    const menu = document.querySelector(".menu")
    menu.classList.remove("hidden")
    anime({
      targets: menu,
      translateX: "0%",
      duration: 400,
      easing: "easeInOutCubic",
    })
  }

  return (
    <StaticQuery
      query={graphql`
        {
          file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <Header>
          <HeaderLeft href="/">
            <Img
              className="logo"
              fixed={data.file.childImageSharp.fixed}
              alt="Artemis Salon"
            />
          </HeaderLeft>
          <HeaderRight>
            <HeaderButton onClick={open_menu}>
              <div></div>
              <div></div>
              menu
            </HeaderButton>
          </HeaderRight>
        </Header>
      )}
    />
  )
}
