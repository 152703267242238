import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"

export const MenuClose = styled.button`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  color: ${colors.olive};
  font-size: 3rem;
  background-color: transparent;
  border: none;
  ${transitions.default};
  line-height: 1ch;
  padding: 1rem;

  &:hover {
    cursor: pointer;
    background-color: ${colors.olive};
    color: ${colors.white};
  }
`

export const Menu = styled.nav`
  z-index: 50;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.black};
  min-height: 500px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${transitions.default};

  &.hidden {
    opacity: 0;
  }
`

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;

  @media (min-width: 1024px) {
    margin-left: 1.5rem;
  }
`

export const MenuItem = styled.li`
  margin: 0.75rem 0;
  overflow: hidden;

  a {
    ${transitions.default}
    ${fonts.play};
    font-size: 2rem;
    color: ${colors.white};
    text-decoration: none;
    position: relative;
    padding: 0 1rem;

    @media (min-width: 768px) {
      font-size: 3rem;
    }

    @media (min-width: 1280px) {
      font-size: 6vmin;
    }

    &::before {
      display: block;
      width: 100%;
      height: 4px;
      background-color: ${colors.olive};
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      ${transitions.default}
      transform: translate(-101%, 50%);
    }

    &:hover {
      color: ${colors.darkolive};

      &::before {
        transform: translate(0, 50%);
      }
    }
  }
`

export const SocialList = styled.ul`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
`

export const SocialItem = styled.li`
  margin: 0 1rem;

  svg {
    color: ${colors.white};
    ${transitions.default};

    &:hover {
      color: ${colors.olive};
    }
  }
`
