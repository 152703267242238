import styled from "@emotion/styled"
import { colors, fonts, transitions } from "../theme"
import { Link } from "gatsby"

export const Header = styled.header`
  z-index: 40;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

export const HeaderLeft = styled.a`
  padding-left: 1rem;
  text-decoration: none;
  max-width: 50vw;
`

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 50vw;
`

export const HeaderLink = styled(Link)`
  text-decoration: none;
  margin-right: 2rem;
  text-transform: uppercase;
  color: ${colors.darkolive};
  ${fonts.mont.bold};
  letter-spacing: 3px;
  font-size: 0.875rem;
  ${transitions.default};
  background-color: ${colors.white};
  padding: 1rem 2.5rem;
  display: none;

  &:hover {
    color: ${colors.olive};
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

export const HeaderButton = styled.button`
  ${fonts.mont.bold};
  color: ${colors.white};
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  padding: 1rem;
  background-color: ${colors.darkolive};

  @media (min-width: 768px) {
    padding: 1.25rem 3rem 1.25rem 2rem;
  }

  &,
  & div {
    ${transitions.default}
  }

  &:hover {
    cursor: pointer;
    color: ${colors.darkolive};
    background-color: ${colors.white};

    div {
      background-color: ${colors.darkolive};
    }
  }

  div {
    width: 75%;
    height: 2px;
    background-color: ${colors.white};

    &:first-of-type {
      margin-left: auto;
      margin-bottom: 0.33rem;
    }

    &:last-of-type {
      margin-right: auto;
      margin-bottom: 0.75rem;
    }
  }
`
